<template>
  <header-template
    v-if="showContent && !is404page"
    v-bind:current-route="currentRoute"
    v-bind:router-params="routerParams"
    v-bind:menu="initialData.menu"
    v-bind:locales="initialData.locales"
    v-bind:selectedLocale="selectedLocale"
    v-on:localeChanged="selectedLocale = $event"
  />
  <router-view v-if="showContent" v-bind:router-params="routerParams" v-bind:locale="selectedLocale" />
  <footer-template
    v-if="showContent && !is404page"
    v-bind:router-params="routerParams"
    v-bind:data="initialData.footer"
  />
  <loading v-bind:hidden="renderFinished" v-if="showPreloader && !is404page" />
  <span ref="scrollTopButton" class="scroll-top-button" @click.self="scrollToTop"></span>
</template>

<script>
import loading from "./components/loading";
import backend from "./services/backend";
import headerTemplate from "./components/layout/header";
import footerTemplate from "./components/layout/footer";
import localeService from "./services/localeService";

export default {
  name: "App",
  inject: ["mq"],
  components: {
    loading,
    headerTemplate,
    footerTemplate,
  },
  data() {
    return {
      currentRoute: null,
      defaultLocale: null,
      selectedLocale: null,
      renderFinished: false,
      showPreloader: true,
      initialData: null,
      breakpointsLoaded: false,
      notRedirectedYet: true,
      is404page: false,
    };
  },

  created() {
    window.addEventListener("scroll", this.handleScroll);
    window.addEventListener("resize", this.handleScroll);
  },
  methods: {
    handleScroll() {
      const showButton = window.scrollY > 200;

      if (showButton) {
        this.$refs.scrollTopButton.classList.add("shown");
        return;
      }

      this.$refs.scrollTopButton.classList.remove("shown");
    },
    scrollToTop() {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
    loadMainData(firstCalling = false) {
      backend.initialData().then(data => {
        this.initialData = data;

        if (firstCalling) {
          setTimeout(() => {
            this.renderFinished = true;
          }, 1500);

          localeService.setDefaultLocale(data.locales.default);
          this.defaultLocale = data.locales.default;
          this.selectedLocale = data.locales.selected;
        }
      });
    },
    updateAppClassname() {
      const classes = Object.entries(this.mq)
        .filter(([, v]) => Boolean(v))
        .map(([k, v]) => (v === true ? k : v));

      if (this.showPreloader) {
        classes.push("preloader-shown");
      }

      document.querySelector("#app").className = classes.join(" ");
    },
  },
  computed: {
    routerParams() {
      if (this.defaultLocale === this.selectedLocale) {
        return {};
      }

      return { locale: this.selectedLocale };
    },
    showContent() {
      return !!this.initialData && this.breakpointsLoaded;
    },
  },
  async mounted() {
    this.loadMainData(true);
    this.updateAppClassname();

    this.breakpointsLoaded = true;
  },
  watch: {
    renderFinished() {
      setTimeout(() => {
        this.showPreloader = false;
      }, 250);
    },
    "$route.name"(routeName) {
      this.currentRoute = routeName;
      this.is404page = routeName === "page-not-found";

      window.scrollTo(0, 0);
    },
    "mq.current"() {
      this.updateAppClassname();
    },
    showPreloader() {
      this.updateAppClassname();
    },
    selectedLocale(newLocale, oldLocale) {
      localeService.setLocale(newLocale);
      const localeChanged = !!oldLocale && oldLocale !== newLocale;
      const defaultLocaleUsed = localeService.isDefaultUsed();
      const redirectLocker = !localeService.defaultIsInitial() && this.notRedirectedYet;

      if (!this.is404page && (localeChanged || (!redirectLocker && defaultLocaleUsed))) {
        this.notRedirectedYet = false;
        this.$router.push({ params: this.routerParams, name: this.$route.name });
        this.loadMainData();
      }
    },
  },
};
</script>

<style lang="scss" rel="stylesheet/scss">
* {
  box-sizing: border-box;
}

html,
body {
  height: 100%;
  background: #ffffff;
}

#app {
  height: 100%;

  &.preloader-shown {
    overflow: hidden;
  }
}

.page-content {
  padding-top: 100px;
  padding-bottom: 150px;
  min-height: 600px;
  position: relative;
}

.scroll-top-button {
  position: fixed;
  width: 40px;
  height: 40px;
  background: url("/images/move_to_top.png") center/75%no-repeat #fff;
  bottom: 40px;
  right: 40px;
  cursor: pointer;
  border-radius: 1px;
  transition: opacity 0.2s ease-in-out;
  z-index: 999;
  opacity: 0;
  pointer-events: none;

  &.shown {
    opacity: 0.7;
    pointer-events: unset;
  }

  &:hover {
    opacity: 1;
  }
}
</style>

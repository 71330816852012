<template>
  <footer class="footer">
    <div class="footer-row-first">
      <div class="container">
        <div class="row">
          <div class="col xs12 m4 phone">
            <a :href="'tel:' + data.phone">{{ data.phone }}</a>
          </div>
          <div class="col xs12 m4 push-m4 email">
            <a :href="'mailto:' + data.email"> {{ data.email }}</a>
          </div>
          <div class="col xs12 m4 pull-m4">
            <nav class="social-icons">
<!--              <a class="instagram" target="_blank" :href="data.social.instagram"></a>-->
<!--              <a class="facebook" target="_blank" :href="data.social.facebook"></a>-->
              <a class="vkontakte" target="_blank" :href="data.social.vkontakte"></a>
              <a class="telegram" target="_blank" :href="data.social.telegram"></a>
              <a class="whatsapp" target="_blank" :href="data.social.whatsapp"></a>
            </nav>
          </div>
        </div>
      </div>
    </div>
    <div class="footer-row-second">
      <div class="container">
        <div class="row">
          <div class="col xs12 m4 push-m4">
            <nav>
              <router-link :to="{ params: routerParams, name: 'tos' }">{{ data.anchors.tos }}</router-link>
              <span class="delimiter"></span>
              <router-link :to="{ params: routerParams, name: 'privacy' }">{{ data.anchors.privacy }}</router-link>
            </nav>
          </div>
          <div class="col xs12 m4 push-m4 designer">
            <span v-html="data.designer"></span>
          </div>
          <div class="col xs12 m4 pull-m8 copyright">
            {{ data.copyright }}
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "footer-template",
  props: {
    data: Object,
    routerParams: Object,
  },
  data() {
    return {};
  },
};
</script>

<style lang="scss" rel="stylesheet/scss">
.footer {
  background-color: #111111;

  a {
    color: inherit;
    font-size: inherit;
  }

  .footer-row-first {
    line-height: 130px;

    .phone,
    .email {
      color: #ffffff;
      font-size: 24px;
      font-weight: 700;
      text-align: left;
    }

    .email {
      text-align: right;
    }
  }
  .footer-row-second {
    line-height: 65px;
    border-top: 1px solid #333333;
    font-size: 13px;
    color: #777777;

    nav {
      text-align: center;

      .delimiter {
        display: inline-block;
        position: relative;
        width: 24px;
        height: 10px;

        &:after {
          display: block;
          content: " ";
          position: absolute;
          width: 4px;
          height: 4px;
          border-radius: 50%;
          margin: auto;
          background: #777777;
          right: 0;
          left: 0;
          top: 0;
          bottom: 0;
        }
      }
    }
    a:hover {
      color: #ffffff;
    }

    .designer {
      text-align: right;
    }
  }

  .social-icons {
    font-size: 0;
    text-align: center;
    line-height: inherit;

    a {
      display: inline-block;
      width: 40px;
      height: 40px;
      background: center/contain no-repeat #000000;
      vertical-align: middle;

      &:not(:first-child) {
        margin-left: 20px;
      }

      &.instagram {
        background-image: url("/images/icons/instagram.svg?2");
      }
      &.facebook {
        background-image: url("/images/icons/facebook.svg?2");
      }
      &.vkontakte {
        background-image: url("/images/icons/vkontakte.svg?2");
      }
      &.telegram {
        background-image: url("/images/icons/telegram.svg?3");
        background-color: #fff;
        background-size: 80%;
      }
      &.whatsapp {
        background-image: url("/images/icons/whatsapp.svg?2");
        background-color: #fff;
        background-size: 110%;
      }
      &:hover {
        background-color: #505050;
      }
    }
  }
}

.laptopPlus .footer {
  .footer-row-second {
    font-size: 14px;
  }
}

.phoneMinus .footer {
  .footer-row-first {
    padding: 60px 0 40px;

    .phone,
    .email {
      text-align: center;
      line-height: 60px;
      font-size: 16px;
    }
    .social-icons {
      line-height: 80px;
    }
  }

  .footer-row-second {
    padding: 40px 0;

    .designer,
    .copyright {
      text-align: center;
    }
  }
}

.tablet .footer {
  .phone,
  .email {
    font-size: 18px;
  }
}
</style>
